:root {
  --bs-laranjo: #ff6a00;
}

.border-laranjo {
  border-color: var(--bs-laranjo) !important;
}

.border-min {
  max-width: 20%;
}

.border-med {
  max-width: 50%;
}

.border-max {
  max-width: 80%;
}

.accordion-body {
  padding: 0px;
}

.accordion-button {
  padding: 0.5em 1.25em;
}

.btn-larango {
  color: #fff;
  background-color: var(--bs-laranjo);
  border-color: var(--bs-laranjo);
}

.btn-outline-larango {
  color: var(--bs-laranjo);
  border-color: var(--bs-laranjo);
}

.btn-outline-larango:hover {
  color: #fff;
  background-color: var(--bs-laranjo);
  border-color: var(--bs-laranjo);
}
.offcanvas-end{
  width: 300px;
}